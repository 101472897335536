/* VARIABLES
--------------------------------------------------------------------------------------------*/

/*
    BRAND COLORS
*/

$brand-primary: #BE1823; 
$brand-primary-darker: darken($brand-primary, 25%);

$brand-secondary: #EDEDED; 
$brand-secondary-darker: darken($brand-secondary, 25%); 

$brand-tertiary:#29235C;
$brand-tertiary-darker: darken($brand-tertiary, 5%); 

$brand-white: #ffffff;
$brand-black: #222222; 
$brand-darkest-gray: #444444;
$brand-dark-gray: #666666;
$brand-gray: #9D9D9C;
$brand-light-gray: #EDEDED;
$brand-lightest-gray: #fafafd;

// for adjustment classes generation
$brand-color-list:  (
    primary: $brand-primary,
    primary-darker: $brand-primary-darker,
    secondary: $brand-secondary,
    secondary-darker: $brand-secondary-darker, 
    tertiary: $brand-tertiary, 
    tertiary-darker: $brand-tertiary-darker,
    black: $brand-black, 
    white: $brand-white, 
    light-gray: $brand-light-gray,
    lightest-gray: $brand-lightest-gray
);


$brand-background-primary: linear-gradient(-135deg, $brand-primary-darker, $brand-primary);
$brand-background-secondary: linear-gradient(-135deg, $brand-secondary-darker, $brand-secondary);
$brand-background-tertiary: linear-gradient(-135deg, $brand-tertiary-darker, $brand-tertiary);

$brand-background-primary-reversed: linear-gradient(135deg, $brand-primary-darker, $brand-primary);
$brand-background-secondary-reversed: linear-gradient(135deg, $brand-secondary-darker, $brand-secondary);
$brand-background-tertiary-reversed: linear-gradient(135deg, $brand-tertiary-darker, $brand-tertiary);

// for adjustment classes generation
$brand-gradient-list:  (
    primary: $brand-background-primary,
    secondary: $brand-background-secondary,
    tertiary: $brand-background-tertiary, 
);


/*
    DEFAULTS
*/

$default-padding: 20px;
$default-border-radius: 6px;// 6px
$default-transition-speed: .3s ease;
$default-box-shadow: 5px 5px 10px rgba(black, .1);

$default-text-color: $brand-tertiary-darker;
$default-text-color-light: $brand-white;
$default-text-color-dark: $brand-tertiary-darker;

$default-icon-color: $brand-tertiary-darker;
$default-icon-color-light: $brand-white;
$default-icon-color-dark: $brand-tertiary-darker;

$default-background-light: $brand-white;
$default-background-dark: $brand-tertiary-darker;

$default-border-color: rgba($brand-dark-gray, .2);  
$default-border: solid 1px $default-border-color;


/*
    OTHER VARIABLES
*/

// SVG
$svg-icon-set-type: 'stroke'; // stroke or fill. 

// MAX CONTENT WIDTH
$max-content-600: 600px;
$max-content-800: 800px;
$max-content-1000: 1000px;
$max-content-1100: 1100px;
$max-content-1200: 1200px;
$max-content-1400: 1400px;
$max-content-1600: 1600px;


/*
    LIST FOR DEFAULT BG COLOR GENERATION
*/

$default-bg-list:  (
    default_light: $default-background-light,
    default_dark: $default-background-dark,
);


/*
    LIST FOR DEFAULT FONT COLOR GENERATION
*/

$default-text-color-list:  (
    default: $default-text-color,
    default_light: $default-text-color-light,
    default_dark: $default-text-color-dark,
);


/*
    LIST FOR DEFAULT FILL COLOR GENERATION
*/

$default-fill-color-list:  (
    default: $default-icon-color,
    default_light: $default-icon-color-light,
    default_dark: $default-icon-color-dark,
);


