/* FIXES
--------------------------------------------------------------------------------------------*/
.ac_menu-2_bar_bottom {
    background-color: $brand-primary;
    a {
        color: #fff;
        font-weight: bold;
    }
}

.ac_menu-2_bar_top{
    background-color: #fff;
    z-index: 100;
}

.ac_menu-2_logo_img {
    z-index: 999;
    height: 130px;
    max-width: 200%;
    width: auto;
    margin-left: -45px;
    margin-top: -65px;
    margin-bottom: -10px;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 200px;
    height: auto;
    margin-top: -12px;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background-color:$brand-darkest-gray;
}

[data-s-type="hero"] .ac_hero_container {
    height: 65vh;
}

[data-m-type="menu-2"] ul.is-dropdown-submenu li.is-submenu-item {
    a {
        color:$brand-tertiary;
        font-weight: normal;
    }
}

.ac_menu-2_main_content {
    a {
        font-size: 20px;
        font-weight: bold;
    }
}

[data-s-type="services"] .ac_item_content {
    border: 2px solid #fff;
    border-radius: 10px;
}

[data-s-id="home-services"] {
    padding-top:20px;
    padding-bottom: 10px;
}

.home .ac_content_1-3 {
    background: $brand-lightest-gray;
    padding:40px;
    border-radius: 10px;
}

@media screen and (max-width: 50em){
    .home .ac_content_1-3 {
        display: none;
    }
}


[data-f-type="footer-1"] .ac_socials {
    //display: block;
}

@media screen and (max-width:1150px){
    .ac_menu-2_logo_img {
        height: 100px;
        max-width: 200%;
        width:auto;
        margin-left:10px;
    }
}

@media screen and (max-width:800px){
    .ac_socials_link {
        //margin-left:45%;
    }
}

.ac_heading_container {
    padding:0 10px;
}

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.ac_content_1-1 {
    padding-left:10px;
}

.ac_heading_title {
    padding-left:10px;
}

#menu-mobile {
    padding-top:20px;
}
@media screen and (max-width: 880px) {
    [data-m-type="menu-2"] + .main-container {
        padding-top: 90px;
    }

    .home [data-m-type="menu-2"] + .main-container {
        padding-top: 70px;
    }

    [data-s-type="hero"] {
        height: 240px;
    }
}
@media screen and (max-width: 500px) {
    .ac_menu-2_mobile_btn {
        display:none;
    }
}

.ac_image-gallery_item_image_container {
    height: 500px;
}

[data-s-type="hero"] .ac_hero_slide_content_text_title * {
    font-size: 50px;
    margin-bottom: 30px;
    text-shadow: 1px 1px #444444;
}

.ac_image-gallery_container:after {
    display: none;
}

.ac_image-gallery_container:before {
    display: none;
}


.gform_wrapper .left_label input.medium, .gform_wrapper .left_label select.medium, .gform_wrapper .right_label input.medium, .gform_wrapper .right_label select.medium {
    width:auto !important;
}

@media screen and (max-width: 880px) {
    .ac_menu-2_logo_img {
        position: absolute;
        clip: rect(0px,170px,70px,0px);
        top: 10px;
        left: -10px;
        width: 170px !important;
    }

    .a_margin-top-10 {
        margin-top: 160px !important;
    }

    .ac_toptasks_item_container {
        width:100% !important;
    }
}

.is-accordion-submenu-parent > a::after {
    margin-right:-50px;
}

.ac_brands_item_container {
    box-shadow: none;
    background: none;
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: left !important;
}

[data-s-type="hero"] .ac_hero_slide_content_text {
    -webkit-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    max-width: 100%;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    min-height: 150px;
    height: 150px;
}


[data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
    padding-top:10px;
}

.ac_menu-2_bar_bottom_container {
    justify-content: center;
    .menu-item {
        padding-right:70px;
        font-size: 17px;
    }
}

[data-m-type="menu-2"] ul.menu.dropdown {
    align-items: center;
}

.ac_menu-2_bar_bottom_content {
    width: 100%;
}
